<template>
  <div class="blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <h2 class="title">MySpanishNow Blog</h2>
      <div v-show="error" class="row">
        <div class="col">
          <div class="alert alert-danger" role="alert">
            {{ error }}
          </div>
        </div>
      </div>
    </section>
    <div class="container 75% posts">
      <section class="row 200%" v-for="row in nrRows" :key="row">
        <div class="col-lg-4" v-for="col in nrColumns(row)" :key="index(row, col)">
          <a :href="`/blog/${allPosts[index(row, col)].permalink}`">
            <div class="card">
              <img
                :src="allPosts[index(row, col)].small_banner"
                class="card-img-top"
                alt="Banner image"
                loading="lazy">
              <div class="card-body">
                <h4 class="card-title">{{ allPosts[index(row, col)].title }}</h4>
                <p class="card-text">{{ allPosts[index(row, col)].content_overview }}</p>
              </div>
            </div>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import BlogPostsApi from '../../api/BlogPosts';

export default {
  name: 'Blog',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      error: false,
      allPosts: [
        {
          "title": "How to find the ideal online tutor/teacher?",
          "permalink": "find-the-perfect-tutor",
          "created": "2022-08-11T16:26:44",
          "last_modified": "2022-08-11T16:26:44",
          "status": "public",
          "content_overview": "Perfection does not exist. But, please, take your time thinking well about what you need - or what you don't ...",
          "small_banner": "/images/blog/find-the-perfect-tutor/small-banner.jpg"
        },
        {
          "title": "Can I learn Spanish with Duolingo?",
          "permalink": "learn-spanish-with-duolingo",
          "created": "2022-08-11T16:26:44",
          "last_modified": "2022-08-11T16:26:44",
          "status": "public",
          "content_overview": "You can learn with Duolingo, as well as by watching YouTube videos, movies or listening to music. But ...",
          "small_banner": "/images/blog/learn-spanish-with-duolingo/small-banner.jpg"
        },
        {
          "title": "Should I take online Spanish lessons or face-to-face?",
          "permalink": "online-lessons-or-face-to-face",
          "created": "2022-08-02T16:26:44",
          "last_modified": "2022-08-02T16:26:44",
          "status": "public",
          "content_overview": "Both of them can work really well. It all depends on YOUR preferences and circumstances...",
          "small_banner": "/images/blog/online-lessons-or-face-to-face/small-banner.jpg"
        },
        {
          "title": "Spanish for Travellers - Asking in a Restaurant",
          "permalink": "spanish-for-travellers/restaurant",
          "created": "2022-09-14T16:26:44",
          "last_modified": "2022-09-14T16:26:44",
          "status": "public",
          "content_overview": "If you would like to order your favourite food in a restaurant and enjoy more your trips learning about culture with locals keep reading...",
          "small_banner": "/images/blog/spanish-for-travellers/restaurant/small-banner.jpg"
        }
      ],
    };
  },
  methods: {
    getAllPosts() {
      BlogPostsApi
        .allPublic()
        .then(({data}) => {
          this.allPosts = data.concat(this.allPosts);
        })
        .catch((error) => {
          this.parseError(error, 'Unable to retrieve all public blog posts');
        })
    },
    nrColumns(row) {
      return Math.min(3, this.allPosts.length - ((row - 1) * 3));
    },
    index(row, col) {
      return (row - 1) * 3 + col - 1;
    },
  },
  created() {
    this.getAllPosts();
  },
  computed: {
    nrRows() {
      return Math.floor((this.allPosts.length - 1) / 3) + 1;
    },
  },
};
</script>

<style scoped>
  figure {
    max-width: 225px;
    margin: 0 0 2em 0;
  }

  figcaption {
    font-size: 0.85em;
    font-style: italic;
    margin-top: -10px;
    text-align: center;
  }

  .blog > .container {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .blog .posts a {
    text-decoration: none;
    color: inherit;
  }

  section > h3 {
    width: 100%;
    padding-left: 3em !important;
  }

  .blog .posts .row {
    margin-bottom: 50px;
  }

  @media screen and (max-width: 1280px) {
    .row > h3 {
      padding-left: 2.2em !important;
    }
  }

  @media screen and (max-width: 736px) {
    .row > h3 {
      padding-left: 1.88em !important;
    }
  }

  @media screen and (max-width: 991px) {
    .blog .posts .col-lg-4 {
      margin-bottom: 25px;
    }

    .blog .posts section:last-child .col-lg-4:last-child {
      margin-bottom: 0;
    }
  }
</style>
