/**
 * Holds all the blog post API requests
 */
import Api from './Api';

const END_POINT = 'blog/posts';

export default {
  get({ permalink }, spinner = true) {
    return Api.get(`${END_POINT}/${permalink}`, null, { spinner });
  },
  all(spinner = true) {
    return Api.get(`${END_POINT}`, null, { spinner });
  },
  allPublic(spinner = true) {
    return Api.get(`${END_POINT}/public`, null, { spinner });
  },
  create(blog, spinner = true) {
    const data = { ...blog };
    delete data.small_banner;
    return Api.post(`${END_POINT}`, data, { spinner });
  },
  update(blog, spinner = true) {
    const data = { ...blog };
    delete data.id;
    delete data.small_banner;
    return Api.put(`${END_POINT}/${blog.permalink}`, data, { spinner });
  },
  delete({ permalink }, spinner = true) {
    return Api.delete(`${END_POINT}/${permalink}`, null, { spinner });
  },
  updateBanner({ permalink }, file, spinner = true) {
    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      spinner,
    };
    const data = new FormData();
    data.append('small_banner', file);
    return Api.put(`${END_POINT}/${permalink}/banner`, data, configs);
  },
  uploadFile({ permalink }, file, spinner = true) {
    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      spinner,
    };
    const data = new FormData();
    data.append('file', file);
    return Api.post(`${END_POINT}/${permalink}/files`, data, configs);
  },
};
